.choose-color {
  &.modal-choose-color{
    position: relative;
    width: 100%;
    .carousel.carousel-slider{
      max-width: 100%;
    }
    .viewTabWrapper{
      top:45px;
      .viewBtn{
        margin-top: 0;
      }
    }
    .wrapperTabColors{
      bottom: 25px;
    }
    &__price {
      font-size: 28px;
      font-family: 'Toyota-Type Regular';
    }
    .title-container{
      margin: 0;
    }
    .rightContentBtnWrapper{
      display: flex;
      align-items: baseline;
      button{
        &:last-child{
          margin-left: 48px;
          border: 1px solid var(--primary-black);
        }
      }

    }
    .wrapperBreadcrumbsBlock{
      margin-bottom: 22px;
      margin-top: 40px;
    }
    .content {
      padding:0;
      align-items: center;
      justify-content: space-between;
      max-height: 688px;
      &.content-column{
        flex-direction: column;
      }
      &-gallery {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100px;
        .carousel-root {
          .carousel.carousel-slider {
            .big-image {
              margin-bottom: 20px;

              img {
                width: 908px;
                // height: 1040px;
              }
            }

            .small-images {
              display: flex;
              width: 940px;
              height: 48px;
              justify-content: center;
              overflow-y: hidden;
              overflow-x: auto;

              &__img-container {
                padding: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;
                margin-right: 8px;

                img {
                  width: 80px;
                  height: 48px;
                }
              }
            }
          }

          .thumbs-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }

      .image-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        &.car{
          position: absolute;
          width: 100%;
          left: 0;
          top: 100px;
        }

        &__image {
          height: 398px;
          margin-bottom: 6px;

          img {
            max-width: 884px;
            width: 100%;
            height: 100%;
          }
        }

        &__slider {
          display: flex;
          align-items: center;
          width: 540px;
          height: 48px;
          position: relative;
          margin-bottom: 30px;
          justify-content: center;
          z-index: 9;
          margin-top: 16px;

          .line {
            width: 50%;
            height: 3px;
          }

          .left-side {
            background: linear-gradient(
                            to left,
                            rgba(228, 228, 228, 1),
                            rgba(228, 228, 228, 0)
            );
          }
          .right-side {
            background: linear-gradient(
                            to right,
                            rgba(228, 228, 228, 1),
                            rgba(228, 228, 228, 0)
            );
            border-radius: 55px;
          }

          .handler-wrapper {
            position: absolute;
          }

          .MuiSlider {
            &-root {
              width: 80%;
            }

            &-thumb {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              border: 1px solid var(--primary-black);
              background-color: var(--white);
              box-shadow: none;
              display: flex;
              justify-content: center;
              align-items: center;

              &::after {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                content: '↔';
                z-index: 99999;
                color: var(--primary-black);
                font-size: 25px;
                margin-top: -6px;
              }
            }
          }
        }

        .colors {
          text-align: center;
          width: 100%;
          display: flex;
          align-items: center;
          .icons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            max-width: 70%;

            &__icon {
              width: 50px;
              height: 50px;
              padding: 5.5px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 8px;

              &:last-child {
                margin-right: 0;
              }

              &_active {
                border: 1px solid var(--gray-6);
              }

              img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                border: 1px solid var(--gray-1);
              }
            }
          }
          &__color-name {
            font-size: 16px;
            color: var(--gray-7);
          }
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        width: 260px;

        ul {
          li {
            display: flex;
            align-items: center;
            height: 62px;
            border-top: 1px solid var(--gray-5);
            font-size: 16px;
            color: var(--primary-black);

            span {
              margin-right: 10px;
              text-align: left;
            }

            &:last-child {
              border-bottom: 1px solid var(--gray-5);
            }
          }
        }
      }
    }
    .wrapperBreadcrumbsPrice{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .wrapperPriceBlock{
        display: flex;
        align-items: baseline;
        margin-top: 0;
        margin-left: 16px;
        .price{
          font-weight: 400;
          font-size: 28px;
          line-height: 32px;
          display: inline-block;
          width: auto;
          color: var(--primary-black);
          text-align: center;
          padding: 0 8px;
        }
        .icon{
          width: 14px!important;
          height: 14px!important;
          margin-left: 8px;
          svg{
            width: 14px;
            height: 14px;
          }
        }
      }
      .wrapperDiscount{
        display: flex;
        align-items: center;
        margin-left: 8px;
        .icon{
          width: 14px!important;
          height: 14px!important;
          margin-left: 8px;
          svg{
            width: 14px;
            height: 14px;
          }
        }
        .package__discount{
          color: #6C7073;
          font-size: 13px;
          line-height: 20px;
          text-decoration: line-through;
        }
      }
    }
    .discPanel{
      .carousel .slider-wrapper{
        max-width: 1100px;
      }
      .carousel .slide{
        left: 0px;

        &.selected{
          left: 0;
          min-width: calc(100% - 400px);
        }
      }
      .carousel .slider-wrapper.axis-horizontal .slider{
        max-width: 100%;
        display: flex;
      }
    }
  }

}
